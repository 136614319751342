'use strict';
import "../../style/demos.css";
import React  from 'react';
import PropTypes from 'prop-types';
import {isEmpty} from 'ramda';
import axios from 'axios';
import {Helmet} from 'react-helmet';

import DemoReviewsDetailPage from '../components/demo_reviews_detail_page';
import { httpRequest, queryApiUrl, log, postHits } from '../general';

class DetailedDemoReview extends React.Component {
    constructor() {
        super();
        this.state = { review: undefined, fetched: false,   error: false };
    }
    
    getDemoReviewFromApiAsync() {
        const api = 'imp_demos/rows/' + this.props.match.params.id + "/?";
        const urlPath = queryApiUrl(api);
        const self = this;
        // Make a request for a user with a given ID
        axios.get(urlPath)
        .then(function (response) {
            window.scrollTo(0, 0);
            const hits = response.data.data.hits + 1; 
            postHits(api, hits);

            self.setState({
                review: response.data.data,
                fetched: true,
             });
         })
        .catch(function (error) {
            log("Error fetching review");
            log(error);
            self.setState({review:undefined, fetched: true, error: true});
        });
    }


    componentWillMount() {
        if (this.props.match.params.id) {
            this.getDemoReviewFromApiAsync();
        }
        window.scrollTo(0, 0);
    }

    shouldComponentUpdate(nextProps, nextState) {
        // log(nextProps);
        // log(nextState);
        if ( nextState.fetched == false ) {
            return false; 
        }

        const isNumber = nextState.review instanceof Array ? false : true;
        if (nextProps.match.params.id != undefined &&  isNumber  && nextProps.match.params.id != nextState.review.id) {
            this.getDemoReviewFromApiAsync();
            return false;
        }

        return true;
    }

    render() {
        const {fetched, review, error} = this.state;
        if (fetched == false) {
            return false;
        }

        if (error == true) {
            return (<div>404: Arvostelua ei löytynyt.</div>);            
        }

        if (isEmpty(review)) {
            return (<div>404: Arvostelua ei löytynyt.</div>);            
        }

        const review_image = "https://www.impe.fi/images/imperiumi-demo.jpg";
        const review_description = review.performer + " / " + review.demoname + " demoarvio (" + review.reviewer +")";
        return (
            <div className="demo-review-detail">
                <Helmet>
                    <title>DEMOARVOSTELU: {review.performer} - {review.demoname} / Imperiumi.net</title>

                    <meta property="title" content={review.performer}/>
                    <meta property="description" content={review_description}/>
                    <meta property="twitter:title" content={review.performer}/>
                    <meta property="twitter:description" content={review_description}/>
                    <meta property="twitter:image" content={review_image}/>
                    <meta property="og:title" content={review.performer}/>
                    <meta property="og:description" content={review_description}/>
                    <meta property="og:image" content={review_image}/>
                </Helmet>

                <DemoReviewsDetailPage review={review} location={this.props.match} width={this.context.layoutState.childrenWidth}/>
            </div>
        );
    }
}

DetailedDemoReview.contextTypes = {
    layoutState: PropTypes.object
};

DetailedDemoReview.propTypes = {
    match: PropTypes.object
};

export default DetailedDemoReview;
