
import React from 'react';
import PropTypes from 'prop-types'
import ScriptTag from 'react-script-tag';

class Ad extends React.Component {

    constructor(...options) {
        super(...options);
        this.releaseTimeout = undefined;
        this.state = {
            released: false
        };
    }

    render() {
        return (
            <ScriptTag isHydrating={true} type="text/javascript" dangerouslySetInnerHTML={{__html: this.props.script}}/>
        )
    
    }
}

Ad.propTypes = {
  script: PropTypes.string.isRequired,
  elementId: PropTypes.string.isRequired,
};

export default Ad;