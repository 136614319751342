"use strict";
import "../../style/headpage.css";
import React  from 'react';
import PropTypes from 'prop-types';
import Dimensions from 'react-dimensions';
import ReactDOMServer from 'react-dom/server';
import { concat, slice, compose, sortWith, descend, prop } from 'ramda';
import { Container, Row, Col, Card,  CardHeader, CardFooter, CardBody, Media } from 'reactstrap';
import ElementCard from './element_card';
import AdPlacer from './ad_placer';
import { linkTo, goTo, log, createRightSideReview, authorLink, paragraphRemoveImagesAndVideos, paragraphOrMaxLength, periodOrMaxLength, createDangerous, createMarkup, paragraphSkipImages, dateConverted, dateDisplayer} from '../general';

class HeadPage extends React.Component {
    constructor(props) {
        super(props);

    }


    render() {
        const max_length_news = 9999;
        const max_length_review = 9999;
        
        var news = undefined;
        if (this.props.news.length > 0) {
            news = this.props.news.map((new_item, index) => {
                const news_id = "news/1/" + new_item.id;
                const news_text_without = paragraphSkipImages(new_item.news);
                const news_text = paragraphRemoveImagesAndVideos(news_text_without);
                const news_text_shorted = paragraphOrMaxLength(news_text, 500);
                const paragraphMarkup = createMarkup(news_text_shorted);
                const news_element = createDangerous(paragraphMarkup);
                const keyCol = "news-" + index;
                const keyCard = "n-" + new_item.id;

                const headerPanel = (header, news_date) => {
                    return (
                        <div>
                            <div className="headpage-news-header">
                                <span>{header}</span>
                                <div className="news-date">
                                    {dateDisplayer(dateConverted(news_date))}
                                </div>
                            </div>
                        </div>                
                    )
                }
                
                return ([
                    <div key={index} className="headpage-news"> 
                        {goTo(news_id, 
                            <Card>
                            <CardHeader>
                                {headerPanel(new_item.topic, new_item.date)}
                            </CardHeader>
                            <CardBody>
                                <div className="headpage-news-list">
                                    {news_element}
                                </div>
                                <div className="news-list-read-more">
                                Lue lisää
                                </div>
                            </CardBody>
                            </Card>     
                        )}               
                    </div>

                ]);
            });
        }

        const xlValue = 4;
        const lgValue = 6;
        const mdValue = 6;
        const smValue = 6;
        const xsValue = 12;

        var reviews = undefined;
        var metaTags = undefined;
        // Find album of the week....
        if (this.props.reviews.length > 0) {
            
            const resultSet = [];
            const scoreSort = sortWith([
                descend(compose(parseFloat, prop('points'))),
                descend(prop('date'))
              ]);

            const albums = scoreSort(this.props.reviews);
            var albumOfMonth = undefined;

            albums.some((album) => {
                if (album.classic == 0) {
                    albumOfMonth = album;
                    return true;
                }
                return false;
            });

            if (albumOfMonth) {
                albumOfMonth.devilmark = 1; 
                resultSet.push(albumOfMonth);
    
            }


            log(this.props.reviews);
            // Add rest 5 albums starting from newest....
            this.props.reviews.forEach((new_elem,index) => {
                log("Reviews");
                log(new_elem);
                if (albumOfMonth != undefined && new_elem.id == albumOfMonth.id)
                    return;
                if (resultSet.length > 11)
                    return;

                resultSet.push(new_elem);
            }, this);
            
            reviews = resultSet
                .map((new_item, index) => {
                    var imageFileName = undefined;
                    const review_id = "reviews/1/" + new_item.id;
                    const review_text = paragraphRemoveImagesAndVideos(new_item.review);
                    const review_text_shorted = periodOrMaxLength(review_text, 300);
                    const paragraphMarkup = createMarkup(review_text_shorted);
                    const review_element = createDangerous(paragraphMarkup);
                    if (new_item.coverart) {
                        const year = slice(0, 4, new_item.date);
                        imageFileName =  "/images/albumcovers/" + year + "/" + new_item.coverart;
                    } 

                    const reviewer = new_item.reviewer != undefined ? authorLink(new_item.reviewer) : undefined;
                    const keyCol = "review-" + index;
                    const keyCard = "r-" + new_item.id;
                    return ([
                        <Col xl={xlValue} lg={lgValue} md={mdValue} sm={smValue} xs={xsValue}>
                            {goTo(review_id,
                                <ElementCard keyName={keyCard} image={imageFileName} link={review_id} performer={new_item.performer} record={new_item.record} points={undefined}
                                    text={review_element}  date={new_item.date} reviewer={reviewer} no_hover={false} image_fix="fix-both" devil_mark={new_item.devilmark}/>
                            )}
                        </Col>
                    ]);
                }
            );
        }

        var interviews = undefined;
        if (this.props.interviews.length > 0) {
            interviews = this.props.interviews.map((new_item, index) => {
                var imageFileName = undefined;
                const interview_id = "articles/1/" + new_item.id;
                if (new_item.indeximage) {
                    imageFileName =  "/images/interviewimages/" + new_item.indeximage;
                } 
                const paragraph = (ingres) => {
                    return (
                        <p>
                            {ingres}
                        </p>
                    )
                }
                const keyCol = "interview-" + index;
                const keyCard = "i-" + new_item.id;

                return ([
                    <Col xl={xlValue} lg={lgValue} md={mdValue} sm={smValue} xs={xsValue}>
                        {goTo(interview_id,
                            <ElementCard keyName={keyCard}  image={imageFileName} link={interview_id}  performer={new_item.performer} record={undefined} points={undefined}
                                text={paragraph(new_item.ingres)} date={new_item.date} no_hover={false} interview={true}/>
                        )}
                    </Col>
                ]);
            });
        }

        var items = undefined;
        if (news && reviews && interviews) {
            const n = concat(news, reviews);
            items = concat(n, interviews);
        }

        const newsLink = goTo("/news/1",<label className="line-text">uutiset</label> );
        const reviewsLink = goTo("/reviews/1",<label className="line-text">arvostelut</label> );
        const articlesLink = goTo("/articles/1",<label className="line-text">artikkelit</label> );

        return (
            <div className="headpage">
                <AdPlacer place={3} pageWidth={this.props.containerWidth} pageHeight={this.props.windowHeight}  marginLeft={15} marginRight={15}/>
                <div className="headpage-separator">
                    {reviewsLink}
                </div>
                <Container fluid={true}>
                    <Row className="show-grid">
                        {reviews}
                    </Row>
                </Container>
                <AdPlacer place={4} pageWidth={this.props.containerWidth} pageHeight={this.props.windowHeight} marginLeft={15} marginRight={15}/>
                <div className="headpage-separator">
                    {articlesLink}
                </div>
                <Container fluid={true}>
                    <Row className="show-grid">
                        {interviews}

                    </Row>
                </Container>
                <AdPlacer place={5} pageWidth={this.props.containerWidth} pageHeight={this.props.windowHeight} marginLeft={15} marginRight={15}/>
                <AdPlacer place={6} pageWidth={this.props.containerWidth} pageHeight={this.props.windowHeight} marginLeft={15} marginRight={15}/>
            </div>
        );
    }   
}

HeadPage.propTypes = {
    windowWidth: PropTypes.number,
    windowHeight: PropTypes.number,
    news: PropTypes.array.isRequired,
    reviews: PropTypes.array.isRequired,
    interviews: PropTypes.array.isRequired,

};


export default Dimensions()(HeadPage);
