"use strict";
import '../../style/articles.css';
import React from 'react';
import PropTypes from 'prop-types'
import Dimensions from 'react-dimensions';
import ReactImageFallback from 'react-image-fallback';
import { Card, Button, CardHeader, CardFooter, CardBody,
    CardTitle, CardText } from 'reactstrap';
import he from 'he';
import classnames from 'classnames';

import ShareButtons from './share_buttons';
import PublishingInfo from './publishing_info';
import { Carousel } from 'react-responsive-carousel';

import { log, createCarousel, createDangerous, createMarkup, removeImages, authorLink, bandLink, serverAddress, dateDisplayer, paragraphSkipImages, removeOldTags } from '../general';
import AdPlacer from './ad_placer';

class ArticleDetailPage extends React.Component {
    constructor(props) {
        super(props);
    }
    
    render() {
        const {location, article, containerWidth} = this.props;
        // log(location);
        if (this.props.article == undefined) {
            return (
                <div>
                    <h3>Artikkelia ei löytynyt</h3>
                </div>
            );
        }
        const ownurl = serverAddress() + location.url;
        const messageToShare = "Imperiumi.net kirjoittaa: " + this.props.article.performer;

        const header = () => {
            return (
                <div>
                    <div className="article-detail-head">
                        <div className="article-name">
                            {this.props.article.performer}
                        </div>
                    </div>

                </div>
            );
        };
        const headerText = header(); 

        const oldCompatibility = removeOldTags(this.props.article.interview);
        const textWithImages = paragraphSkipImages(oldCompatibility);
        
        const test = createCarousel(textWithImages);
        const images = [];
        if (test !== undefined) {
            test.map((item) => {
                const filename = item.match('<img src="(.*?)"');
                const caption = item.match('alt="(.*?)"');
                const captionText = caption !== null ? caption[1] : undefined;
                if (filename !== null ) {
                    const imageName = filename[1];
                    log(imageName);
                    images.push((<div>
                    <img src={""+ imageName}/>
                    <p className="legend">{captionText}</p>
                    </div>));
                } 
            });
        }
            

        var text;
        var carousel = undefined;
        if (test !== undefined) {
            carousel = 
                <Carousel autoPlay={true}>
                    {images}
                </Carousel>
            text = removeImages(textWithImages);
        } else {
            text = textWithImages
        }

        const paragraphMarkup = createMarkup(text)
        const element = createDangerous(paragraphMarkup);
        const article_image =  this.props.article.indeximage != "" ? "/images/interviewimages/" + this.props.article.indeximage : undefined;
        const altText = this.props.article.performer;
        const album_image =  undefined; //this.props.article.albumcover != "" ? <img className="cover" src={"/images/albumcovers/" + this.props.article.albumcover}/> : undefined;
        const image = article_image == null ? undefined : <ReactImageFallback src={article_image} fallbackImage="/images/default_article_card.png" className="article-image" alt={altText}/>                    
        
        const mainos15 = classnames('mainos', 'paikka_15');    
        return (
            <div className="article_detail">
                <Card>
                <CardHeader className="my-card-header">
                    {headerText}
                </CardHeader>
                <CardBody>
                <PublishingInfo 
                    photographerName={this.props.article.photos}
                    authorName={this.props.article.author}
                    date={this.props.article.date}
                    />
                <div className="article-detail-hero">
                    {image}
                    {album_image}
                    <div className="title2">
                        {this.props.article.ingres}
                    </div>
                </div>
                <div className="article-share-buttons-right">
                <ShareButtons 
                    width={this.props.containerWidth}
                    message={messageToShare}
                    currentUrl={ownurl} 
                    bandLink={this.props.article.homepage} 
                    />                                
                </div>                
                <div className="article-detail-body">
                    {element}
                    {carousel}
                </div>
                </CardBody>
                </Card>
                <div key="ma_15" className={mainos15}>
                    <AdPlacer place={15} pageWidth={containerWidth} pageHeight={400} marginLeft={15} marginRight={15}/>
                </div>
            </div>
        );
    }    
}

ArticleDetailPage.propType = {
    article: PropTypes.object.isRequired,
    location: PropTypes.object
};

export default Dimensions()(ArticleDetailPage);