'use strict';
import "../../style/articles.css";
import React  from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import ArticleDetailPage from '../components/article_detail_page';
import { httpRequest, queryApiUrl, log, postHits } from '../general';
import {Helmet} from "react-helmet";

class ArticleDetail extends React.Component {
    constructor() {
        super();
        this.state = { article: undefined, fetched: false, error: false };
    }
    
    
    getArticleFromApiAsync() {
        const api = 'imp_interviews/rows/' + this.props.match.params.id + "/?";
        const urlPath = queryApiUrl(api);

        const self = this;
        // Make a request for a user with a given ID
        axios.get(urlPath)
        .then(function (response) {
            const hits = response.data.data.hits + 1; 
            postHits(api, hits);
            window.scrollTo(0, 0);
            self.setState({
                article: response.data.data, 
                fetched: true,
             });
         })
        .catch(function (error) {
            log("Error fetching article");
            log(error);
            self.setState({article: undefined, fetched: true, error: true});
        });
    }

    componentWillMount() {
        window.scrollTo(0, 0);
        this.getArticleFromApiAsync();
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextState.fetched == false)
            return false;

        const isNumber = nextState.article instanceof Array ? false : true;
        if (nextProps.match.params.id != undefined &&  isNumber  && nextProps.match.params.id != nextState.article.id) {
            this.getArticleFromApiAsync();
            return false;
        }
                
        return true;


    }

    render() {
        const {fetched, article} = this.state;
        if (fetched == false) {
            return null;
        }        
        const article_image =  "https://www.impe.fi" + (article.indeximage != "" ? "/images/interviewimages/" + article.indeximage : undefined);

        return (
            <div className="article-detail">
                <Helmet>
                    <title>ARTIKKELI: {article.performer} - {article.ingres} / Imperiumi.net</title>
                    <meta property="title" content={article.performer}/>
                    <meta property="description" content={article.ingres}/>
                    <meta property="twitter:title" content={article.performer}/>
                    <meta property="twitter:description" content={article.ingres}/>
                    <meta property="twitter:image" content={article_image}/>
                    <meta property="og:title" content={article.performer}/>
                    <meta property="og:description" content={article.ingres}/>
                    <meta property="og:image" content={article_image}/>
                </Helmet>
                <ArticleDetailPage article={article} location={this.props.match}/>
            </div>
        );
    }
}

ArticleDetail.propTypes = {
    match: PropTypes.object
};

export default ArticleDetail;
