import React from 'react';
import ReactDOM from 'react-dom';
import ReactDOMServer from 'react-dom/server';
import PropTypes from 'prop-types'
import {find, propEq} from 'ramda';
import AdSense from 'react-adsense';
import classnames from 'classnames';
import Center from 'react-center';
import ReactImageFallback from 'react-image-fallback';
import Ad from './ad';
import { UncontrolledTooltip } from 'reactstrap';
import { httpRequest, queryApiUrl, log, improveAdsLink, improveAdsLink2, createDangerous, tradeDoublerAdsLink, taboolaLink } from '../general';

class AdPlacer extends React.Component {
    constructor(...options) {
        super(...options);
        this.state =  {
            showFull: false,
        }
        this.showFull = this.showFull.bind(this);
        
    }

    showFull(e, link) {
        e.preventDefault();
        
        const {showFull} = this.state;
        if (showFull && link) {
            window.open(link, '_blank');
        }
        
        this.setState(prevState => ({
            showFull: !prevState.showFull}));
    }

    render() {
        const { pageWidth, pageHeight, place, marginLeft, marginRight} = this.props;
        const adClassName = "kuva_" + place;
        var adStyle = undefined;
        var adProvider = undefined;
        if (this.context.layoutState.ads == undefined || this.context.layoutState.adTypes == undefined) {
            return undefined;
        }
        const ad = find(propEq('place', place))(this.context.layoutState.ads); //=> {a: 2}

        const detectAdWidth = (pageWidth) =>  {

        }

        if (ad) {
            var adWidth = undefined;
            var adHeight = undefined;
            var adType = undefined;
            var adImageName = undefined;
            
            if (ad.max_width <= pageWidth) {
                if (ad.max_autowidth) {
                    adWidth = pageWidth > ad.max_width ? ad.max_width : pageWidth;
                } else {
                    adWidth = ad.max_width;
                }

                adHeight = ad.max_height;
                adType = ad.ad_type_max;
                adImageName = ad.image_left;
            } else if (ad.min_width < pageWidth) {
                if (ad.min_autowidth) {
                    adWidth = pageWidth;
                } else {
                    adWidth = ad.min_width;
                }
                adHeight = ad.min_height;
                adType = ad.ad_type_min;
                adImageName = ad.image_right;
            } else {
                adWidth = ad.ad_mobile_width;
                adHeight = ad.ad_mobile_height;
                adType = ad.ad_type_mobile;
                adImageName = ad.image_mobile;
            }
            log("Mainostagit");
            log("Pagewidth " + pageWidth);
            log("Paikka " + place);
            log("Leveys " + adWidth);
            log("Tyyppi  " + adType);

            if (adWidth == 0 || adHeight == 0) {
                return (<div>Ei mainoksia</div>);
            }
            // Find right ad type from adtypes list.
            const adTypeItem  = find(propEq('ad_type', adType), this.context.layoutState.adTypes);
            if (adTypeItem == undefined) {
                adProvider = undefined;

            } else {
                // const adTypeItem = this.findAdType(adType);
                switch(adTypeItem.provider) {
                    case 'improveads':  
                        adProvider = <Ad script={improveAdsLink(adTypeItem.ad_identifier)} elementId={adClassName}/>
                        break;
                    case 'new_improveads':
                        adProvider = <div><div id={adTypeItem.ad_identifier} className={adTypeItem.ad_identifier}></div>
                                <Ad script={improveAdsLink2(adTypeItem.ad_identifier)} elementId={adClassName}/>
                        </div>
                        break;
                    case 'improveads_video':
                        adProvider = 
                            <div className="video-ad" id="video-ad" key="video-ad" style={{"max-width":"800px","margin-left":"auto","margin-right":"auto"}}>
                                <Ad script={improveAdsLink(adTypeItem.ad_identifier)} elementId={adClassName}/>
                            </div>
                        break;
                    case 'nuclearblast':
                        adProvider = <iframe key="nuclearblast" id='a8919370' name='a8919370' src='https://metalforum.com/mfblast/www/delivery/afr.php?zoneid=3&cb=INSERT_RANDOM_NUMBER_HERE'  frameBorder='0' scrolling='no' width='120' height='600'><a href='https://metalforum.com/mfblast/www/delivery/ck.php?n=a8bbc618&cb=INSERT_RANDO M_NUMBER_HERE' target='_blank'><img src='http://metalforum.com/mfblast/www/delivery/avw.php?zoneid=3&cb=INSERT_RANDOM_NUMBER_HERE&n=a8bbc618' alt='' /></a></iframe>                    
                        break;
                    case 'tradedoubler':
                        adProvider = [
                            <Center>
                                <Ad script={tradeDoublerAdsLink(adTypeItem.ad_identifier)} elementId={adClassName}/>
                            </Center>
                        ];
                        break;
                    // Mainostapetti verho jutulla tai mobile kokosivu 
                    case 'imperiumi_tapetti':
                        if (pageWidth > 1340) {
                            const background = classnames('ad-background', {full: this.state.showFull});
                            const imperiumiTapettiImage = "/images/bannerimages/" + adImageName;

                            adProvider = ([
                                    <div key="imperiumi_tapetti" className={background}>
                                        <a href="#" onClick={event => this.showFull(event, ad.link)}> 
                                            <ReactImageFallback
                                                src={imperiumiTapettiImage}
                                                fallbackImage="/images/bannerimages/default_banner.png"
                                                className="ad-background-image"
                                            />
                                        </a>
                                    </div>
                            ]);     
                        } else {
                            /*
                            const background = classnames('ad-background', {full: this.state.showFull});
                            const imperiumiTapettiImage = "/images/bannerimages/" + adImageName;

                            adProvider = ([
                                    <div className={background}>
                                        <a href="#" onClick={event => this.showFull(event, ad.link)}> 
                                            <ReactImageFallback
                                                src={imperiumiTapettiImage}
                                                fallbackImage="/images/bannerimages/default_banner.png"
                                                className="ad-background-image"
                                            />
                                        </a>
                                    </div>
                            ]);
                            */
                           adProvider = undefined;                                 
                        }      
                        adWidth = 0;
                        adHeight = 0;         
                    break;

                    case 'google':
                        adStyle={width: adWidth, height: adHeight};
                        // log(adStyle);
                        adProvider = <AdSense.Google key="google" client='ca-pub-5079152667084378'
                            slot={adTypeItem.ad_identifier}/>;
                    break;
                    case 'imperiumi':
                        const imperiumiStyle = {
                            width:adWidth, height:"auto"
                        };
                        const imperiumiBannerImage = "/images/bannerimages/" + adImageName;
                        var tooltip = undefined;
                        if (ad.tooltip && ad.tooltip.length > 1) {
                            tooltip = 
                                <UncontrolledTooltip placement="right" target={adClassName}>
                                    {ad.tooltip}
                                </UncontrolledTooltip>

                        }
                        adProvider = 
                            <div key="imperiumi">
                                <a href={ad.link} id={adClassName} target="_blank">
                                    <img src={imperiumiBannerImage}/>
                                </a>
                                {tooltip}
                            </div>;
                    break;
                    default:
                        const imgStyle = {
                            width: adWidth,
                            height: adHeight,
                            paddingBottom: '10px',
                        };
                        adProvider = <img key="sample" src="/images/ad_sample.png" style={imgStyle}/>;
                        break;
                }
            }

            adStyle = {
            }
        }
        
        return (
            <div key={adClassName} id={adClassName} className={adClassName} style={adStyle}>
                {adProvider}
            </div>
        );
    }
}    

AdPlacer.contextTypes = {
    layoutState: PropTypes.object
};


AdPlacer.propTypes = {
    pageWidth: PropTypes.number.isRequired,
    pageHeight: PropTypes.number.isRequired,
    place: PropTypes.number.isRequired,
    marginLeft: PropTypes.number,
    marginRight: PropTypes.number,
};

  
export default AdPlacer;