'use strict';
import '../../style/footer.css';
import React from 'react';
import FontAwesome from 'react-fontawesome';
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom';
import { compose, equals, indexOf, prop } from 'ramda';
import classnames from 'classnames';
import { Nav, NavItem, NavLink, Collapse, Well, UncontrolledTooltip } from 'reactstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { log } from '../general';


class FooterNavigation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {"tekijat-open" : true};
    }

    clickEvasteet = () => {
        log("Click evasteet");
        if(typeof window.__tcfapi !== 'undefined') {
            window.__tcfapi('displayConsentUi',2,function(){});
        }
        else{
            window.location='https://www.impe.fi/pages/1/4';
        };
    };

    render() {


        return (
            <div>
                <footer className="footer">
                    <Nav pills justified>
                        <LinkContainer to="/pages/1/2">
                            <NavItem>
                                <NavLink>
                                    <div id="ukk">
                                        <FontAwesome name='info-circle' size='2x'/>
                                        <div className="footer-link-text">
                                            UKK
                                        </div>
                                        <UncontrolledTooltip placement="top" target="ukk">
                                            Usein kysytyt kysymykset
                                        </UncontrolledTooltip>
                                    </div>
                                </NavLink>
                            </NavItem>
                        </LinkContainer>
                        <LinkContainer to="/profiles">
                            <NavItem>
                                <NavLink>
                                    <div id="tekijat" className="footer-link">
                                        <FontAwesome name='address-book' size='2x'/>
                                        <div className="footer-link-text">
                                            Tekijät
                                        </div>
                                        <UncontrolledTooltip placement="top" target="tekijat">
                                            Kuka mitä häh?
                                        </UncontrolledTooltip>
                                    </div>
                                </NavLink>
                            </NavItem>
                        </LinkContainer>                            
                        <LinkContainer to="/pages/1/3">
                            <NavItem>
                                <NavLink>
                                    <div id="mediakortti">
                                        <FontAwesome name='euro' size='2x'/>
                                        <div className="footer-link-text">
                                            Mediakortti
                                        </div>
                                        <UncontrolledTooltip placement="top" target="mediakortti">
                                            Mainosta Imperiumissa
                                        </UncontrolledTooltip>
                                    </div>
                                </NavLink>
                            </NavItem>                  
                        </LinkContainer>  
                        <LinkContainer to="/admin/gigs">
                            <NavItem>
                                <NavLink>
                                    <div id="gig-tip">
                                        <FontAwesome name='music' size='2x'/>
                                        <div className="footer-link-text">
                                            Jätä keikkailmoitus
                                        </div>
                                        <UncontrolledTooltip placement="top" target="gig-tip">
                                            Jätä keikkailmoitus
                                        </UncontrolledTooltip>
                                    </div>
                                </NavLink>
                            </NavItem>
                        </LinkContainer>                                                    
                        <NavItem onClick={this.clickEvasteet}>
                            <NavLink>
                                <div id="cookie-tip">
                                    <FontAwesome name='gear' size='2x'/>
                                    <div className="footer-link-text">
                                        Evästevalinnat
                                    </div>
                                    <UncontrolledTooltip placement="top" target="cookie-tip">
                                        Evästeet
                                    </UncontrolledTooltip>
                                </div>
                            </NavLink>
                        </NavItem>
                    </Nav>
                </footer>
            </div>
        );
    }
};

export default FooterNavigation;
