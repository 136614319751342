'use strict';
import "../../style/articles.css";
import React  from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import {Helmet} from 'react-helmet';
import PageDetailPage from '../components/page_detail_page';
import { httpRequest, queryApiUrl, log } from '../general';

class PageDetail extends React.Component {
    constructor() {
        super();
        this.state = { page: undefined, fetched: false, error: false };
    }
    
    getPageFromApiAsync() {
        const api = 'imp_pages/rows/' + this.props.match.params.id + "/?";
        const urlPath = queryApiUrl(api);

        const self = this;
        // Make a request for a user with a given ID
        axios.get(urlPath)
        .then(function (response) {
            window.scrollTo(0, 0);            
            self.setState({
                page: response.data.data, 
                fetched: true,
             });
         })
        .catch(function (error) {
            log("Error fetching page");
            log(error);
            self.setState({page: undefined, fetched: true, error: true});
        });

    }

    componentWillMount() {
        this.getPageFromApiAsync();
        window.scrollTo(0, 0);        
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextState.fetched == false)
            return false;
        
        const isNumber = nextState.page instanceof Array ? false : true;
        if (nextProps.match.params.id != undefined &&  isNumber  && nextProps.match.params.id != nextState.page.id) {
            this.getPageFromApiAsync();
            return false;
        }            
        return true;
    }

    render() {
        const {fetched, page} = this.state;
        if (fetched == false) {
            return null;
        }        
        const image = "https://www.impe.fi/images/basic-logo.png";
        return (
            <div className="pages-detail">
                <Helmet>
                    <title>PLUS: {page.title} / Imperiumi.net</title>

                    <meta property="title" content={page.title}/>
                    <meta property="description" content={page.title}/>
                    <meta property="twitter:title" content={page.title}/>
                    <meta property="twitter:description" content={page.title}/>
                    <meta property="twitter:image" content={image}/>
                    <meta property="og:title" content={page.title}/>
                    <meta property="og:description" content={page.title}/>
                    <meta property="og:image" content={image}/>
                </Helmet>

                <PageDetailPage page={page} location={this.props.match}/>
            </div>
        );
    }
}

PageDetail.propTypes = {
    match: PropTypes.object
};

export default PageDetail;
